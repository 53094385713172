import React, {FC, useEffect, useState} from 'react';
import {DataTable } from 'primereact/datatable';
import {Column, ColumnEditorOptions, ColumnEvent} from 'primereact/column';

import {InputText } from 'primereact/inputtext';
import { Product, ProductListItem, ProductVariant, getProducts, markProductdeleted, updateProductVariant } from '../api/products';
import CreateModal from '../pages/client/Products/createModal';
import { Button } from 'primereact/button';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { convertIntToPrice } from '../helpers/converters';
import { categories } from '../helpers/values';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { getRequest } from '../api';
import { Tag } from 'primereact/tag';

interface ColumnMeta {
    field: string;
    header: string;
    sortable: boolean;
    filterElement?: () => JSX.Element;
    editor?: (options: ColumnEditorOptions) => JSX.Element;
}

const ProductsList: FC = () => {
    const [products, setProducts] = useState<ProductListItem[] | null>();
    const [currentProduct, setCurrentProduct] = useState<Product>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({page: 1, perPage: 10, pageCount: 0, total: 0});
    const [filters, setFilters] = useState<Record<string, unknown>>({main: true});
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState({field: '', order: 'ASC'});
    useEffect(() => {
        const setProductData = async () => {
            const response = await getProducts({filters, pagination, sort});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
        }
        setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sort])

    const setTagFilter = (e: MultiSelectChangeEvent) => {
        const value = e.target.value;
        setSelectedTags(value);
        setFilters({...filters, tags: value});
    }

    const setSortFkt = async (event: any) => {
        setSort({field: event.sortField, order: event.sortOrder === 1 ? 'ASC' : 'DESC'})
    }

    const setPage = async (event: any) => {
        const response = await getProducts({filters, pagination: {...pagination, page: event.page + 1, perPage: event.rows}});
        if(response) {
            const {data, ...paginationPayload} = response;
            setPagination(paginationPayload);
            setProducts(data);
        }
    }

    const weclappRefetch = async () => {
        await getRequest('weclapp/products/');
        await getRequest('weclapp/products/packaging-units');
        refetch();
    }
        
    
    const refetch = async () => {
        setFilters({...filters});
    }

    const createProduct = () => {
        setCurrentProduct(undefined);
        setOpenModal(true);
    }

    const deleteProduct = async (id: string) => {
        await markProductdeleted(id);
        refetch();
    }

    const closeModal = () => {
        setOpenModal(false);
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
        setFilters({...filters, search: value});
    }

    const textTemplate = (field: any, rowData: any) => {
        return <p className={'text-secondary-1'}>{rowData[field]}</p>;
    };

    const priceTemplate = (field: any, rowData: any) => {
        return <p className={'text-secondary-1'}>{convertIntToPrice(rowData[field])}</p>;
    }
    const tagsTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{rowData['tags']?.join(', ')}</p>;
    }

    const deleteProductTemplate = (rowData: any) => {
        return rowData['isDeleted'] ? <Tag onClick={() => deleteProduct(rowData['id'])} value={'Marked for deletion'} severity={'warning'}></Tag> : <Button className="pi pi-trash text-secondary-1" onClick={() => deleteProduct(rowData['id'])}/>
    }

    const bodyTemplate = (field: string, rowData: ProductVariant) => {
        switch (field) {
            case 'price':
            case 'compareAtPrice':
            case 'b2bPrice':
                return priceTemplate(field, rowData);
            case 'tags':
                return tagsTemplate(rowData);
            case 'delete':
                return deleteProductTemplate(rowData);
            default:
                return textTemplate(field, rowData);
        }
    };

    const tagFilter = () => {
        return <span className="p-input-icon-left ">
                <i className="pi pi-tags" />
                <MultiSelect value={selectedTags} options={categories} onChange={setTagFilter} placeholder="Tags" className="p-2 pl-8" />
            </span>
    }

    const titleFilter = () => {
        return <span className="p-input-icon-left ">
                <i className="pi pi-search" />
                <InputText value={search} onChange={onSearchChange} placeholder="Suchen" className="p-2 pl-8" />
            </span>
    }

    const fieldEditor = (options: ColumnEditorOptions) => {
        return options ? <InputNumber value={options.value} onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback?.(e.value)} /> : (<></>);
    };
    
    const onCellEditComplete = async (e: ColumnEvent) => {
        let { rowData, newValue, field } = e;
        rowData[field] = newValue;
        if(rowData.variantId) {
            await updateProductVariant(rowData.variantId.toString(), {b2bPrice: rowData.b2bPrice, minOrderQuantity: rowData.minOrderQuantity});
        }
    };
    
    const productColumns: ColumnMeta[] = [
        {field: 'title', header: 'Name', sortable: true, filterElement: titleFilter},
        {field: 'sku', header: 'SKU', sortable: true},
        {field: 'price', header: 'Preis', sortable: true},
        {field: 'b2bPrice', header: 'B2B Preis', sortable: true, editor: fieldEditor},
        {field: 'packagingQuantity', header: 'Verpackungseinheit', sortable: false},
        {field: 'inventoryQuantity', header: 'Inventar', sortable: false},
        {field: 'reservedQuantity', header: 'Reserviert', sortable: false},
        {field: 'tags', header: 'Tags', sortable: false, filterElement: tagFilter},
        {field: 'taxes', header: 'Steuern', sortable: false},
        {field: 'delete', header: 'Löschen', sortable: false},
    ]

    return (
        <div className="card">
            <CreateModal isOpen={openModal} closeModal={closeModal} refetch={refetch} product={currentProduct} />
            <div className="flex flex-row justify-end">
            <Button onClick={createProduct} label='Produkt anlegen' className='border border-midblue bg-midblue hover:bg-transparent hover:text-midblue text-white py-1 px-2 mb-1'/>
            <Button onClick={weclappRefetch} label='Weclapp Produkte aktualisieren' className='border border-midblue bg-midblue hover:bg-transparent hover:text-midblue text-white py-1 px-2 mb-1 ml-2'/>
            </div>
            <DataTable 
                lazy
                filterDisplay="row"
                value={products || []}
                paginator
                emptyMessage="Keine Produkte gefunden"
                totalRecords={pagination.total}
                rows={pagination.perPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onPage={async (event) => await setPage(event)}
                onSort={setSortFkt}
                sortField={sort.field}
                sortOrder={sort.order === 'ASC' ? 1 : -1}
            >
                    {productColumns.map(({field, header, sortable, filterElement, editor}) => {
                    return filterElement ? <Column key={field} field={field} header={header} style={{width: '25%' }}
                    body={(rowData) => bodyTemplate(field, rowData)}
                    sortable={sortable}
                    filter filterElement={filterElement} showFilterMenu={false}
     /> : <Column key={field} field={field} header={header}
                                   body={(rowData) => bodyTemplate(field, rowData)}
                                   sortable={sortable}
                                   editor={editor ? editor : undefined}
                                   onCellEditComplete={editor ? onCellEditComplete : undefined}
                    />;
                })}
                </DataTable>
        </div>
    );
}
export default ProductsList;
