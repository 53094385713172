import React, { useEffect, useState } from "react";
import { MerchantType, OpeningHours, getMerchant, openCloseMerchant, updateMerchant, getMerchantByAddress, orderlordSyncMerchant } from "../api/merchants";
import { ProductListItem, detachProductFromMerchant, getProducts, getStockLevels, updateMerchantProduct } from "../api/products";
import AttachProductModal from "../pages/client/Products/attachModal";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions, ColumnEvent } from "primereact/column";
import { convertIntToPrice } from "../helpers/converters";
import { Button } from "primereact/button";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { MerchantOpeningHoursModal } from "./Modals/MerchantOpeningHoursModal";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { categories } from "../helpers/values";
import { Dropdown } from "primereact/dropdown";
import EditMerchantModal from "./Modals/EditMerchantModal";
import { useAppSelector } from "../hooks/reduxHooks";
import BulkUpdateSockLevels from "./Modals/BulkUpdateSockLevels";
import BulkDownloadStockLevels from "./Modals/BulkDownloadStockLevels";
import { fetchOrderlordOrders } from "../api/orders";
import SyncOrdersModal from "./Modals/SyncOrdersModal";

interface props {
 id: string;
}
const MerchantProducts: React.FC<props> = ({id}) => {
    const {isAdmin} = useAppSelector((state) => state.userReducer);
    const [merchantId, setMerchantId] = useState(parseInt(id))
    const [merchant, setMerchant] = useState<MerchantType>();
    const [products, setProducts] = useState<ProductListItem[]>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);
    const [openHourModal, setOpenHourModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [csvModal, setCSVModal] = useState<boolean>(false);
    const [csvDownload, setCSVDownloadModal] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<string | string[]>([]);
    const [selectedRelation, setSelectedRelation] = useState<string>('');
    const [sort, setSort] = useState({field: '', order: 'DESC'});
    const [pagination, setPagination] = useState({page: 1, perPage: 10, pageCount: 0, total: 0});
    const [filters, setFilters] = useState<Record<string, unknown>>({merchantId: id});
    const [search, setSearch] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const setTagFilter = (e: MultiSelectChangeEvent) => {
        const value = e.target.value;
        setSelectedTags(value);
        setFilters({...filters, tags: value});
    }
    const setRelationFilter = (e: MultiSelectChangeEvent) => {
        const value = e.target.value;
        setSelectedRelation(value);
        setFilters({...filters, targetRelation: value});
    }

    useEffect(() => {
        if(parseInt(id) !== merchantId) setMerchantId(parseInt(id));
        if(merchantId !== filters.merchantId) setFilters({...filters, merchantId});
        if(merchant?.id !== merchantId) {
            const setMerchantData = async () => {
                const response = await getMerchant(id);
                setMerchant(response);
            }
            setMerchantData();
        }
    }, [merchantId, id, filters, merchant?.id])

    useEffect(() => {
        const setProductData = async () => {
            const response = await getStockLevels({filters, pagination, sort});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
        }
        setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sort])

    useEffect(() => {
        const setProductData = async () => {
            const response = await getStockLevels({filters, pagination});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
        }
        if(pagination.total > 10 && products?.length && products?.length < 10) setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.total, products?.length])

    const setIsOpen = async () => {
        if(merchant && merchant.id) {
            const newMerchant = await openCloseMerchant(merchant.id, !merchant.isOpen);
            setMerchant(newMerchant);
        }
    }

    const getMerchantsByAddress = async (param: Record<string, unknown>) => {
        const params = { filters: { ...param } };
        const newMerchants = await getMerchantByAddress(params);
        if(!newMerchants) return;
        const updateMerchant = newMerchants.delivery.find((merchant) => merchant.id === merchantId);
        if (updateMerchant) setMerchant(updateMerchant)
    }

    const fetchOrders = async () => {
        if (isAdmin && merchantId) {
            await fetchOrderlordOrders(merchantId);
        }
    }

    const setSortFkt = async (event: any) => {
        setSort({field: event.sortField, order: event.sortOrder === 1 ? 'ASC' : 'DESC'})
    }

    const setPage = async (event: any) => {
            const response = await getProducts({filters, pagination: {...pagination, page: event.page + 1, perPage: event.rows}});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
    }

    const updateMerchantHours = async (payload: OpeningHours) => {
        if(merchant) {
            const newMerchant = await updateMerchant({...merchant, openingHours: payload});
            setMerchant(newMerchant);
        }
    }

    const refetchProducts = () => {
        setPagination({page: 1, pageCount: pagination.pageCount, perPage: 10, total: pagination.total})
    }

    const syncOrders = async () => {
        if (merchant && !merchant.syncOrderlord) {
            setOpenSyncModal(true);
        }
        else if(merchantId) {
            const newMerchant = await orderlordSyncMerchant(merchantId, false, new Date().toISOString());
            setMerchant(newMerchant);
        }
    }

    const deleteProduct = async (id: string) => {
        if(merchantId) {
            await detachProductFromMerchant(id);
            const newProducts = products?.filter((product) => product.id !== id)
            await setProducts(newProducts);
        }
    }

    const deleteProductTemplate = (rowData: any) => {
        return <Button className="pi pi-trash text-secondary-1" onClick={() => deleteProduct(rowData['id'])}/>
    }
    const priceTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{convertIntToPrice(rowData['price'])}</p>;
    }
    const tagsTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{rowData['tags']?.join(', ')}</p>;
    }
    const relationTemplate = (rowData: any) => {
        return <p className={`h-8 aspect-square rounded-full ${rowData['targetRelation'] < 0.5 ? 'bg-red-500' : rowData['targetRelation'] < 0.66 ? 'bg-orange-500' : 'bg-customgreen'}`} />;
    }

    const onCellEditComplete = async (e: ColumnEvent) => {
        let { rowData, newValue, field } = e;
        rowData[field] = newValue;
        if(merchantId) {
            const updatedProduct = await updateMerchantProduct(rowData.id, {price: rowData.price, amount: rowData.amount, targetAmount: rowData.targetAmount, triggerAmount: rowData.triggerAmount});
            if(updatedProduct) {
                const newProducts = products?.map((product) => {
                    if(product.id === `${updatedProduct.id}`) return {...product, price: updatedProduct.price, amount: updatedProduct.amount, targetAmount: updatedProduct.targetAmount, triggerAmount: updatedProduct.triggerAmount, targetRelation: updatedProduct.targetRelation};
                    return product;
                })
                await setProducts(newProducts);
            }
        }
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
        setFilters({...filters, search: value});
    }

    const tagFilter = () => {
        return (
            <span className="p-input-icon-left ">
                <i className="pi pi-tags" />
                <MultiSelect value={selectedTags} options={categories} onChange={setTagFilter} placeholder="Tags" className="p-2 pl-8" />
            </span>
        );
    }

    const titleFilter = () => {
        return (
            <span className="p-input-icon-left ">
                <i className="pi pi-search" />
                <InputText value={search} onChange={onSearchChange} placeholder="Suchen" className="p-2 pl-8" />
            </span>
        );
    }
    const getOrderDay = (day: number) => {
        switch (day) {
            case 0: return 'Sonntag';
            case 1: return 'Montag';
            case 2: return 'Dienstag';
            case 3: return 'Mittwoch';
            case 4: return 'Donnerstag';
            case 5: return 'Freitag';
            case 6: return 'Samstag';
            default: return 'UNKOWN';
        }
    }
    const relationOptions = [
        {label: 'Alle', value: ''},
        {label: 'rot', value: 'low'},
        {label: 'orange', value: 'medium'},
        {label: 'grün', value: 'high'},
    ]
    const relationFilter = () => {
        return (
            <span className="p-input-icon-left ">
                <Dropdown value={selectedRelation} options={relationOptions} onChange={setRelationFilter} className="p-2" />
            </span>
        );
    }

    const priceEditor = (options: ColumnEditorOptions) => {
        return options ? <InputNumber value={options.value} onValueChange={(e: InputNumberValueChangeEvent) => {
            const newValue = e.value && e.value > 0 ? e.value : 0;
            options.editorCallback?.(newValue);
        }} /> : (<></>);
    };
    return <div className="card">
        {merchantId && openModal && <AttachProductModal merchantId={merchantId} closeModal={() => setOpenModal(false)} refetch={refetchProducts} />}
        {merchantId && merchant?.openingHours && <MerchantOpeningHoursModal merchantId={merchantId} openingHours={merchant?.openingHours} isOpen={openHourModal} onSave={(hours: OpeningHours) => updateMerchantHours(hours)} onClose={() => setOpenHourModal(false)} />}
        {merchantId && isAdmin && <EditMerchantModal merchant={merchant} isOpen={editModal} closeModal={() => setEditModal(false)} refetch={() => {return}}/>}
        {merchantId && <BulkUpdateSockLevels merchant={merchant} isOpen={csvModal} closeModal={() => setCSVModal(false)} refetch={refetchProducts}/>}
        {merchantId && csvDownload && <BulkDownloadStockLevels merchantId={merchantId} isOpen={csvDownload} closeModal={() => setCSVDownloadModal(false)} />}
        {merchant && merchantId && openSyncModal && <SyncOrdersModal merchantId={merchantId} isOpen={openSyncModal} closeModal={() => setOpenSyncModal(false)} />}
        <div className="border-b border-gray-200 pb-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{merchant?.name}</h3>
                <h5 className="my-2 text-sm text-gray-500" >Öffnungszeiten:</h5>
            <div className="mt-2 flex items-center justify-between sm:flex-nowrap">
                <table className="mt-2 max-w-4xl text-sm text-gray-500">
                    <tbody>
                    <tr>
                        <th className="text-left pr-2">Montag</th>
                        <td>{merchant?.openingHours.monday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Dienstag</th>
                        <td>{merchant?.openingHours.tuesday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Mittwoch</th>
                        <td>{merchant?.openingHours.wednesday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Donnerstag</th>
                        <td>{merchant?.openingHours.thursday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Freitag</th>
                        <td>{merchant?.openingHours.friday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Samstag</th>
                        <td>{merchant?.openingHours.saturday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    <tr>
                        <th className="text-left pr-2">Sonntag</th>
                        <td>{merchant?.openingHours.sunday.map(({start_time, end_time}, index) => {return `${start_time}-${end_time}${(index + 1) < merchant.openingHours.monday.length ? ' & ' : ' Uhr'}`})}</td>
                    </tr>
                    </tbody>
                </table>
                <div className="flex flex-col justify-start">
                    <InputText placeholder="Adresse suchen..." value={address} onChange={(e) => setAddress(e.target.value)} className="w-20 mr-2" />
                    <Button label="Senden" className="p-button-secondary" onClick={() => getMerchantsByAddress({address})} />
                    <p>Entfernung: {merchant?.distanceInM && (merchant?.distanceInM / 1000) + 'km'}</p>
                    <p>Lieferzeit: {merchant?.durationInS && (Math.round(merchant.durationInS  / 60) + 'min')}</p>
                    <p>Liefergebühr: {merchant?.deliveryCost  && convertIntToPrice(merchant.deliveryCost)}</p>
                    <p>Min. Bestellwert: {merchant?.minPrice && convertIntToPrice(merchant?.minPrice)}</p>
                    <ul className="list-disc">
                        <li>Liefertag: {getOrderDay(merchant?.reorderDay || 99)}</li>
                        <li>Breitengrad: {merchant?.latitude}</li>
                        <li>Längengrad: {merchant?.longitude}</li>
                    </ul>
                </div>
                <div className="flex">
                    {isAdmin &&
                    <div className="ml-4 mt-2 flex flex-col gap-2 flex-shrink-0">
                        <button
                            type="button"
                            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                            onClick={fetchOrders}
                        >Fetch Orderlord Orders</button>
                        {merchant?.orderlordId && (<button
                            type="button"
                            className={merchant.syncOrderlord ? "mr-2 relative inline-flex items-center rounded-md bg-red-500 border-red-500 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500" : "mr-2 relative inline-flex items-center rounded-md bg-customgreen border-customgreen border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-customgreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customgreen"}
                            onClick={syncOrders}
                        >{merchant.syncOrderlord ? 'Orderlord NICHT syncen' : 'Orderlord syncen'}</button>)}
                        <button
                            type="button"
                            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                            onClick={() => setOpenHourModal(true)}
                        >Öffnungszeiten anpassen</button>
                        <button
                            type="button"
                            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                            onClick={() => setEditModal(true)}
                        >Lager bearbeiten</button>
                    </div>
                    }
                    <div className="ml-4 mt-2 flex flex-col gap-2 flex-shrink-0">
                        {merchant?.isOpen ? (
                            <button
                                type="button"
                                className="mr-2 relative inline-flex items-center rounded-md bg-red-600 border-red-600 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-300"
                                onClick={() => setIsOpen()}
                            >Onlineshop schliessen</button>
                        ) : (
                            <button
                                type="button"
                                className="mr-2 relative inline-flex items-center rounded-md bg-customgreen px-3 py-2 text-sm font-semibold text-white shadow-sm border-customgreen border hover:bg-transparent hover:text-customgreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customgreen"
                                onClick={() => setIsOpen()}
                            >Onlineshop öffnen</button>
                        )}
                        {isAdmin &&
                        <>
                            <button
                                type="button"
                                className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                                onClick={() => setCSVModal(true)}
                            >Bulk Update CSV</button>
                            <button
                                type="button"
                                className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                                onClick={() => setCSVDownloadModal(true)}
                            >Produkte Download</button>
                        </>
                        }
                        <button
                            type="button"
                            className="mr-2 relative inline-flex items-center rounded-md bg-customblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                            onClick={() => setOpenModal(true)}
                        >Produkt hinzufügen</button>
                    </div>
                </div>
            </div>
        </div>
        <DataTable 
            lazy
            value={products || []}
            editMode="cell"
            onPage={async (event) => await setPage(event)}
            paginator
            rowsPerPageOptions={[10, 25, 50, 100, 250]}
            totalRecords={pagination.total}
            rows={pagination.perPage}
            emptyMessage="Keine Produkte gefunden"
            filterDisplay="row"
            onSort={setSortFkt}
            sortField={sort.field}
            sortOrder={sort.order === 'ASC' ? 1 : -1}
        >
            <Column field='title' header='Produktname' sortable filter filterElement={titleFilter} showFilterMenu={false} />
            <Column field='vendor' header='Hersteller' sortable />
            <Column field='price' header='Preis' onCellEditComplete={onCellEditComplete} editor={(options) => priceEditor(options)} sortable body={priceTemplate} />
            <Column field='amount' header='Auf Lager' onCellEditComplete={onCellEditComplete} editor={(options) => priceEditor(options)} sortable />
            <Column field='triggerAmount' header='Triggerbestand' onCellEditComplete={onCellEditComplete} editor={(options) => priceEditor(options)} sortable />
            <Column field='targetAmount' header='Sollbestand' onCellEditComplete={onCellEditComplete} editor={(options) => priceEditor(options)} sortable />
            <Column field='targetRelation' filter filterElement={relationFilter} showFilterMenu={false} body={relationTemplate} sortable />
            <Column field="tags" header="Tags" body={tagsTemplate} filter filterElement={tagFilter} showFilterMenu={false} />
            <Column field='delete' header='' body={deleteProductTemplate} />
        </DataTable>
    </div>;
}
export default MerchantProducts;